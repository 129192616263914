import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { navigate } from 'gatsby-link'
import { Link } from "gatsby"

import { faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'

function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}

export default function ContactPage() { 
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
            'form-name': form.getAttribute('name'),
            ...state,
        }),
        })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error))
    }

    const toggleModal = () => {
        setState({ ...state, modal: !state.modal })
    }

    return (
        <Layout>
            <SEO title="Contactez votre plombier à Mions" />
            <section className="section has-background-primary" id="adresse">
                <div className="container">
                    <h2 className="title">
                        <Link to="/plomberie" className="has-text-white">Plombier à Mions</Link>
                    </h2>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <h1 className="title">Nous appeler</h1>
                    <div className="content">
                        <a href="tel:0698999696" class="button is-success">
                        <span class="icon"><FontAwesomeIcon icon={faPhone} /></span>
                        <strong>06 98 99 96 96</strong>
                        </a>
                    </div>
                </div>
            </section>
            <section className="section" id="message">
                <div className="container">
                    <h1 className="title">Nous envoyer un message</h1>
                    <div className="content">
                        <p>
                        N’hésitez pas à nous adresser vos demandes à l’aide de ce formulaire de contact, nous vous répondrons dans les plus brefs délais.
                        </p>
                        <form name="contact" method="POST" action="/sent" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify="true" onSubmit={handleSubmit}>
                            <input type="hidden" name="bot-field" value="contact"/>
                            <div className="field">
                                <label className="label" htmlFor="nom">Nom</label>
                                <div className="control has-icons-left">
                                    <input className="input" type="text" name="Nom" id="nom" placeholder="Votre nom" onChange={handleChange}></input>
                                    <span class="icon is-small is-left"><FontAwesomeIcon icon={faUser} /></span>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label" htmlFor="telephone">Numéro de téléphone</label>
                                <div className="control has-icons-left">
                                    <input className="input" type="text" name="Telephone" id="telephone" placeholder="Votre Numéro de téléphone" onChange={handleChange}></input>
                                    <span class="icon is-small is-left"><FontAwesomeIcon icon={faPhone} /></span>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label" htmlFor="email">Email</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input" type="email" name="Email" id="email" placeholder="Votre adresse e-mail" onChange={handleChange}></input>
                                    <span className="icon is-small is-left">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </span>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label" htmlFor="sujet">Sujet</label>
                                <div className="control">
                                    <div className="select">
                                        <select name="sujet" id="Sujet" onChange={handleChange}>
                                            <option></option>
                                            <option>Demande de devis</option>
                                            <option>Climatisation</option>
                                            <option>Chauffage</option>
                                            <option>Plomberie</option>
                                            <option>Adoucisseur d'eau</option>
                                            <option>Autre</option>
                                        </select>
                                    </div>
                                </div>
                                </div>

                                <div className="field">
                                <label className="label" htmlFor="message">Message</label>
                                <div className="control">
                                    <textarea className="textarea" name="Message" id="message" placeholder="Votre message" onChange={handleChange}></textarea>
                                </div>
                                </div>

                                <div className="field">
                                <div className="control">
                                    <input type="checkbox" onClick={() => setState({ ...state, agreed: !state.agreed })} value={state.agreed} style={{marginRight: 5}}></input>
                                    <label className="checkbox">
                                        J'accepte les <a onClick={toggleModal}>conditions d'utilisation</a>.
                                    </label>
                                </div>
                                </div>

                                <div className="field is-grouped">
                                <div className="control">
                                    <button type="submit" className="button is-link" disabled={!state.agreed}>Envoyer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="section" id="adresse">
                <div className="container">
                    <h1 className="title">Nous retrouver</h1>
                    <div className="content">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1394.1606356545763!2d4.944294012576224!3d45.66445039753463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4c49f611c91e5%3A0xcf8b761b2a200d15!2sAir%20water%20system!5e0!3m2!1sen!2sfr!4v1586130928710!5m2!1sen!2sfr" width="100%" height="460px" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
            </section>
            <div className={`modal ${state.modal ? "is-active" : ""}`} >
                <div className="modal-background" onClick={toggleModal} />
                <div className="modal-content">
                    <div className="box">
                        <h1 className="title">Conditions d'utilisation</h1>
                        <p className="content">
                            Afin de traiter votre demande, AIR WATER SYSTEM devra effectuer un traitement des données personnelles vous concernant. Les données sont conservées pendant 2 ans à compter de notre dernier contact et sont destinées exclusivement à AIR WATER SYSTEM, elles ne seront pas transmises à des tiers.
                            <br></br><br></br>
                            Conformément à la loi Informatique et Libertés du 6 janvier 1978 modifiée, vous bénéficiez d’un droit d’accès et de rectification des données vous concernant, d’un droit d’opposition pour motifs légitimes, d’un droit à l’effacement des données. Vous disposez également d’un droit à la récupération et à la portabilité des données dans certains cas spécifiquement prévus par la loi.
                            <br></br><br></br>
                            Pour l’exercice de vos droits veuillez contacter :
                            <br></br>
                            <strong>AIR WATER SYSTEM, 4 rue frederic chopin 69780 MIONS</strong>
                            <br></br>
                            <strong>airws@live.fr</strong>
                        </p>
                    </div>
                </div>
                <button class="modal-close is-large" aria-label="close" onClick={() => {setState({ ...state, modal: !state.modal })}}></button>
            </div>
        </Layout>
    )
}
